/* website: 2136-mercedes-benz-blainville
 * created at 2024-09-27 13:00 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/mercedes-benz.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.dealer__mercedes-benz-blainville{
    .vehicle-overview{
      .tile-payment-info .payment-rebates .payment-rebate{
        border-top: 1px solid #00adef !important;
      }
    }
  }
}
